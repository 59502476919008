@use '@adnova/jf-ng-components/config' as *;

input.mat-input-element,
input.mat-input-element:disabled,
mat-label {
  line-height: 14px;
}

input.mat-input-element,
input.mat-input-element:disabled {
  color: #000;
}

mat-form-field.mat-form-field-disabled .mat-form-field-outline div {
  background: #d0d0d0;
}

mat-form-field.mat-form-field-invalid {
  .mat-form-field-outline-thick {
   color: $color-error !important;
  }

  .mat-input-element,
  .mat-form-field-label {
    caret-color: $color-error !important;
    color: $color-error !important;
  }
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .ng-star-inserted {
  color: #000;
  background: #fff;
  padding: 0 3px;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.ng-star-inserted mat-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.ng-star-inserted .ng-star-inserted {
  padding: 0;
}

label .ng-star-inserted {
  display: inline-block;
}


