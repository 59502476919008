@forward './styles/global';
@forward './styles/material';
@use 'material-icons/iconfont/material-icons.css';

@use '@adnova/jf-ng-components/config' as *;
// @use '@adnova/jf-ng-components/base'; FIXME: Einkommentieren, wenn die Buttons gegen die der Library ausgetauscht wurden.


@layer bootstrap, material, base, generic, component, app;

@layer bootstrap {
  @import 'bootstrap/scss/bootstrap';
}
